
body{
  overflow-x: hidden;
}
.imagecontainer{

max-height: 100vh;
max-width: 100vw;
 overflow: hidden;
 position: fixed;

}
.imagecontainer img{
  zoom: 0.4;
}
.mainContainer{
  position: absolute;
}
.textcontainer, .formContainer{

  width: 80vw;
  padding: 50px;
  
  margin-left: 10vw;
  border-radius: 20px;
}
.textcontainer{
  margin-top: 10vw;
  background-color: rgba(0, 55, 0, 0.7);
}
h1{
  color: white;
}
.textcontainer p{
  color: white;
  font-size: 20px;
}

.applyContainer, .signupcontainer{
  background-color: rgba(0, 55, 0, 0.7);
  text-align: center;
  padding: 20px;
  border-radius: 20px;
}
.applyContainer p, .signupcontainer p{
  color: white;
  font-weight: bold;
}
.memberButton{
  font-weight: bold;
}

.header{
  color: white;
}

@media (max-width: 440px) {
.textcontainer, .formContainer{
  background-color: transparent;
  padding: 0;
  margin-bottom: 30px;
}
.textcontainer p{
  font-size: 15px;
}
.formContainer{
  margin-top: 30px;
}
.imagecontainer{
  max-height: 110vh;
}
}
@media (min-width: 1400px) {
  .textcontainer, .formContainer{
    width: 50vw;
    margin-left: 25vw;
  }
}
